import { disableTimeUnits, enableTimeUnits } from '@/api/pmtimeunit';

const disableTimeUnit = {
	id: 'disableTimeUnit',
	selectionType: 'multiple',
	label: 'pmtimeunit.actions.disableTimeUnit',
	functionality: 'UPDATE_PMTIMEUNIT',
	checkAvailability: function (timeunit) {
		return timeunit && timeunit.disabled === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmtimeunit.actions.disableTimeUnit');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmtimeunitid: registries[i].pmtimeunitid
			});
		}

		const data = await disableTimeUnits(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableTimeUnit = {
	id: 'enableTimeUnit',
	selectionType: 'multiple',
	label: 'pmtimeunit.actions.enableTimeUnit',
	functionality: 'UPDATE_PMTIMEUNIT',
	checkAvailability: function (timeunit) {
		return timeunit && timeunit.disabled === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmtimeunit.actions.enableTimeUnit');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmtimeunitid: registries[i].pmtimeunitid
			});
		}

		const data = await enableTimeUnits(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

export default {
	actions: [disableTimeUnit, enableTimeUnit]
};
